import { ActionContext } from 'vuex';

import { IAuthenticationState } from '@/interfaces/Authentication';
import { IRootState } from '@/interfaces/RootState';
import { getTokenPopup } from '@/plugins/msal';
import $http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    accessToken: localStorage.getItem('access_token') || '',

    username: localStorage.getItem('username') || '',

    email: localStorage.getItem('email') || '',

    tenant: localStorage.getItem('tenant') || '',
  },

  getters: {
    getAccessToken: (state: IAuthenticationState): string => state.accessToken,

    getUsername: (state: IAuthenticationState): string => state.username,

    getEmail: (state: IAuthenticationState): string => state.email,
  },

  mutations: {
    SET_ACCESS_TOKEN(state: IAuthenticationState, payload: string): void {
      localStorage.setItem('access_token', payload);
      state.accessToken = payload;
    },

    SET_USERNAME(state: IAuthenticationState, payload: string): void {
      localStorage.setItem('username', payload);
      state.username = payload;
    },

    SET_EMAIL(state: IAuthenticationState, payload: string): void {
      localStorage.setItem('email', payload);
      state.email = payload;
    },
    SET_TENANT(state: IAuthenticationState, payload: string): void {
      debugger;
      localStorage.setItem('tenant', payload);
      state.tenant = payload;
    },
  },

  actions: {
    /**
     * Authenticate an existing user.
     *
     * @param {ActionContext<IAuthenticationState, IRootState>} { commit }
     * @return {*}  {Promise<void>}
     */
    async authenticate(
      { commit }: ActionContext<IAuthenticationState, IRootState>,
    ): Promise<void> {
      try {
        const response = await getTokenPopup();
        const accessToken: string = response?.accessToken || '';
        const username: string = response?.account?.name || '';
        const email: string = response?.account?.username || '';
        commit('SET_ACCESS_TOKEN', accessToken);
        commit('SET_USERNAME', username);
        commit('SET_EMAIL', email);
      } catch (error) {
        throw error.response;
      }
    },
    // eslint-disable-next-line consistent-return
    async getTenant(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { commit }: ActionContext<IAuthenticationState, IRootState>,
    ): Promise<void> {
      try {
        debugger;
        const response = await $http.Api({
          method: 'GET',
          url: '/tenant',
        });
        console.log(`response on auth.ts: ${response}`);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
