import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

export default function useAuthentication(): {
  accessToken: ComputedRef<string>;
  username: ComputedRef<string>;
  email: ComputedRef<string>;
  authenticate: () => Promise<void>;
  getTenant: () => Promise<void>;
  } {
  const store = useStore();

  const accessToken = computed((): string => store.getters['Authentication/getAccessToken']);

  const username = computed((): string => store.getters['Authentication/getUsername']);

  const email = computed((): string => store.getters['Authentication/getEmail']);

  async function authenticate(): Promise<void> {
    try {
      await store.dispatch('Authentication/authenticate');
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line consistent-return
  async function getTenant(): Promise<void> {
    try {
      debugger;
      console.log('Tenant response');
      const response = await store.dispatch('Authentication/getTenant');
      console.log(response);
      localStorage.setItem('tenant', response);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    accessToken,
    username,
    email,
    getTenant,
    authenticate,
  };
}
